const initState = {
	menu: '#datingverse',
	accessAccount: undefined,
	currentChainId: undefined
}

export const reducer = (state=initState,action)=>{
	const { type,data} = action
	switch(type){
		case 'SET_MENU':
			return {
				...state,
				menu:data
			}
		case 'SET_ACCOUNT':
			return {
				...state,
				accessAccount:data
			}
		case 'SET_CHAINID':
			return {
				...state,
				currentChainId:data
			}
		default:
			return initState
	}
}
