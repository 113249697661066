import { Soon }   from './soon'

export const HappyDating = (props)=>{
	return(
		<div className="main-wrap dating">
			<div className="container">
				<h4 className="page-title mt60 mb60">HAPPY DATING</h4>
				
				<div className="flex flex-btw mb30">
					<div className="flex-item">
						<div className="fea-title mb30"><span className="d-icon icon-a"></span>3D / VR</div>
						<div className="fea-des">
							Datingverse supports VR devices.Users can wear VR glasses for online dating with other visitors' avatars.  At the same time, Datingverse is customizing a set of standard interface protocols for accessing terminal interactive hardware that meets safety standards, such as smart masturbation cups, smart dildo, touch systems, scent systems, etc., to help users feel the most realistic intimacy during the dating process.
						</div>
						
						<div className="fea-title mb30"><span className="d-icon icon-b"></span>CUSTOMIZABLE AVATARS </div>
						<div className="fea-des">
							The Datingverse user's avatar can be designed by the user self through the engine provided by Datingverse. At the same time, Datingverse offers tens of thousands of avatar products, the combination of which can help the user create a unique avatar.
						</div>
						
						<div className="fea-title mb30"><span className="d-icon icon-c"></span>HARDWARE</div>
						<div className="fea-des">
							Users can also use the real-time motion capture system to present highly reproducible movements and expressions in the datingverse. The information conveyed by these movements and expressions during the process of dating is the key to a perfect online dating experience.
						</div>
						
						<div className="fea-title mb30"><span className="d-icon icon-d"></span>Dating to earn</div>
						<div className="fea-des">
							You can set a threshold for dating you, and eventually exchange $DVC for ETH. $DVC is based on the 1155 protocol and the total number of issues will be 10b.
						</div>
					</div>
					<div className="img-wrap">
						<img className="fea-img mb20" src={require('../img/dataing/1.png')} alt="" />
						<img className="fea-img mb20" src={require('../img/dataing/2.png')} alt="" />
						<img className="fea-img mb20" src={require('../img/dataing/3.png')} alt="" />
						<img className="fea-img" src={require('../img/dataing/4.png')} alt="" />
					</div>
				</div>
				<Soon/>
			</div>
		</div>
	)
}
