import {useState,useEffect}          from 'react'
import { ethers }                              from 'ethers'
import { getProcess,getTokenSupply } from '../tools/api'
import { useSelector,useDispatch }               from 'react-redux'
import { message }                   from './messageBox'
import {ConnectWallet}      from './web3/connect'

const {ethereum} = window

export const MintPage = () => {
	const [ totalSupply, setTotalSupply ] = useState(500)
	const currAccessAccount = useSelector(state => state.accessAccount)
	const dispatch = useDispatch()
	
	const [count,setCount] = useState(2)
	function choose(num){
		const arr = document.querySelectorAll('.mint-top-main_checkbox')
		for ( let i = 0; i < arr.length; i ++ ) {
			arr[i].className = 'mint-top-main_checkbox'
		}
		arr[num-1].className = 'mint-top-main_checkbox active'
		setCount(num)
	}
	function getProcessInfo(){
		showToast()
		getProcess(ethers.utils.getAddress(currAccessAccount)).then(res=>{
			hideToast()
			if ( res.code === 200 ) {
				if ( res.data.status ) {
					message.success('Success',res.data.result)
				}else {
					message.info('Tips',res.data.result,false)
				}
			}
		})
	}
	function openMetaMaskUrl(url) {
		const a = document.createElement("a");
		a.href = url;
		a.target = "_self";
		document.body.appendChild(a);
		a.click();
		a.remove();
	}
	async function validatePermission(){
		if ( ethereum ) {
			if ( currAccessAccount ) {
				getProcessInfo()
			}else {
				const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
				if ( accounts.length !== 0 ) {
					const account = accounts[0]
					dispatch({type:'SET_ACCOUNT',data:account})
					getProcessInfo()
				} else {
					message.error('Error','Please connect your wallet first!')
				}
			}
		}else {
			if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
				openMetaMaskUrl("https://metamask.app.link/dapp/datingverse.org/mint");
			}else {
				message.error('Error','Make sure you have Metamask installed!')
			}
		}
	}
	
	const checkDate = ()=>{
		let endTime = new Date('2022/05/19 12:00:00')
		const difference = new Date().getTimezoneOffset() * 60 * 1000
		const GMTTime = new Date().getTime() + difference
		const begin = new Date(GMTTime)
		return endTime < begin
	}
	function showToast (text='Loading...') {
		let toast = document.querySelector('.toast')
		if ( toast.style.display !== 'block' ) {
			toast.style.display = 'block'
		}
	}
	function hideToast(){
		document.querySelector('.toast').style.display = 'none'
	}
	
	useEffect(()=>{
		window.scrollTo(0,0)
		
		getTokenSupply().then(res=>{
			if ( res.status === '1' ) {
				setTotalSupply(res.result)
			}
		})
	},[])
	
  return(<main className="main-wrap mint">
		<div className="container pt148 mb120">
			{/* <div className="sm-show page-title mb50" style={{maxWidth:"66%"}}>Diamond Mint of Datingverse Genesis NFT</div> */}
			<div className="mint-top mb120 flex-center_hor">
				<div className="mint-top-left">
					<video id="video" className="mint-video mb50"
								 src={require('../video/datingverse.mp4')}
								 x5-video-player-fullscreen="false"
								 x5-playsinline="true"
								 playsInline
								 webkit-playsinline="true"
								 muted
								 autoPlay
								 loop/>
				</div>
				<div className="mint-top-right">
					<p>get your</p>
					<p>datingverse</p>
					<p className="flex">minting</p>
					
					<div className="mint-top-main">
						<div className="mint-top-main_title">You can free mint NOW</div>
						<p className="sm-show mb20"/>
						<p className="mint-top-main_des">How many NFTs do you want to mint? <br/>(limited to 5 per address)</p>
						<div className="text-center">
							<div id="option" className="grid grid-col-10 mb20 mt20">
								<div className="col-span-2">
									<div onClick={(e)=>choose(1)} className="mint-top-main_checkbox">1</div></div>
								<div className="col-span-2">
									<div onClick={(e)=>choose(2)} className="mint-top-main_checkbox">2</div></div>
								<div className="col-span-2">
									<div onClick={(e)=>choose(3)} className="mint-top-main_checkbox">3</div></div>
								<div className="col-span-2">
									<div onClick={(e)=>choose(4)} className="mint-top-main_checkbox">4</div></div>
								<div className="col-span-2">
									<div onClick={(e)=>choose(5)} className="mint-top-main_checkbox active">5</div></div>
							</div>
						</div>
						<div className="mint-top-main_title mb20" style={{lineHeight:'24px'}}>TOTAL: 0 ETH</div>
						<ConnectWallet data={{count: count}}/>
						<p className="mt20 mint-top-main_des">If you encounter any problems during the minting process, feel free to access #mint-help in Discord.</p>
					</div>
				</div>
			</div>
			<div className="page-title text-center mb60 sm-hide">Diamond Mint of Datingverse Genesis NFT</div>
			<div className="mint-bottom grid grid-col-12 text-center lineH_0">
				<div className="col-span-6 fee">
					<div className="icon icon-fee"/>
					<div className="sm-wrap">
						<div className="page-summary-title mt30">Mint 1 Get 1 Free</div>
						<p>Mint 1 Genesis NFT, get 1 Diamond ID NFT free</p>
					</div>
				</div>
				<div className="col-span-6 price">
					<div className="icon icon-price"/>
					<div className="sm-wrap">
						<div className="page-summary-title mt30">Price Guarantee</div>
						<p>Allowing minters to return the NFTs minted at cost <br/>(except gas fee)</p>
					</div>
				</div>
			</div>
		</div>
		<div className="toast text-center" style={{display: 'none'}}>Loading</div>
	</main>)
}
