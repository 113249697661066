import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {Provider} from 'react-redux'
import store from './redux/index'

import './style/main.scss';
import './tool'

import {Header}      from './components/header'
import {Footer}      from './components/footer'
import {DatingSpace} from './components/datingSpace'
import {HappyDating} from './components/happyDating'
import {Datingland}  from './components/datingland'
import {AboutDvc}    from './components/aboutDvc'
import {MintPage}    from './components/mintPage'

import {
  BrowserRouter,
  Routes,
  Route,
}                from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Header/>
      <Routes>
        <Route index element={<App />}/>
        <Route path="/space" element={<DatingSpace />}/>
        <Route path="/dating" element={<HappyDating />}/>
        <Route path="/land" element={<Datingland />}/>
        <Route path="/dvc" element={<AboutDvc />}/>
        <Route path="/mint" element={<MintPage />}/>
      </Routes>
      <Footer/>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
