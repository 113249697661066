import { Soon }   from './soon'

export const DatingSpace = ( props ) => {
	return (
		<div className="main-wrap space">
			<div className="container">
				<h4 className="page-title mt60 mb60">DATING SPACE</h4>
				<div className="page-summary">
					The more you explore the datingverse, the more you will find a wide variety of dating spaces, such as midnight
					clubs with huge dance floors that can accommodate thousands of people at the same time, such as bars with
					great music tastes, such as infinity pools, beaches, airplane cabins, classrooms, offices, prisons,
					hospitals...These dating spaces can be created by users themselves through the powerful online engine provided
					by datingverse, and if you want, you can rent this dating space to other visitors on a short term basis, and
					they can use this dating space for dating in a separate process, which is unimaginable in the real world.
				</div>
				
				<div className="flex flex-btw mb60 mt60">
					<div className="img-wrap grid grid-col-12 gap-8">
						<img className="col-span-6" src={ require('../img/space/1.png') } alt="" />
						<img className="col-span-6" src={ require('../img/space/2.png') } alt="" />
						<img className="col-span-6" src={ require('../img/space/3.png') } alt="" />
						<img className="col-span-6" src={ require('../img/space/4.png') } alt="" />
						<img className="col-span-6" src={ require('../img/space/5.png') } alt="" />
						<img className="col-span-6" src={ require('../img/space/6.png') } alt="" />
						
					</div>
					<img className="img-right" src={ require('../img/space/right.png') } alt="" />
				</div>
				<Soon />
			</div>
		</div> )
}
