export const Footer = (props) => {
	
	function showToast(){
		
		let toast = document.querySelector('.toast')
		if(toast.style.display !== 'block'){
			toast.style.display = 'block'
			setTimeout(()=>{
				toast.style.display = 'none'
			},2000)
		}
	}
	return(
		<footer className="footer">
			<div className="container">
				<div className="flex flex-center_ver flex-center_hor">
					<div className="footer-connect">
						<div className="mb30">
							<img src={require('../img/logo2.png')} alt="" style={{height:'52px'}}/>
						</div>
						<a href="https://twitter.com/datingverse" rel="noopener noreferrer" className="iconfont icon-tw" target="_blank"/>
						<a href="https://discord.gg/datingverse" rel="noopener noreferrer" className="iconfont icon-dis" target="_blank"/>
						<a href="https://datingverse.medium.com" rel="noopener noreferrer" className="iconfont icon-med" target="_blank"/>
						<a href="https://www.instagram.com/datingverse" rel="noopener noreferrer" className="iconfont icon-ins" target="_blank"/>
						<a href="mailto:hello@datingverse.org" rel="noopener noreferrer" className="iconfont icon-mail"/>
						<a href="https://t.me/datingverse" rel="noopener noreferrer" className="iconfont icon-telegram" target="_blank"/>
						<a href="https://opensea.io/collection/datingverse" rel="noopener noreferrer" className="iconfont icon-boat" target="_blank"/>
					</div>
					<div className="footer-content">
						<p><a href="mailto:hello@datingverse.org">Contact Us: hello@datingverse.org</a></p>
						<p className="pointer" onClick={showToast}>Digital Ownership License</p>
						<p ><a href="https://www.getrevue.co/profile/datingverse" target="_blank">Subscribe To Our Newsletter</a></p>
						<p className="cr">Copyright © 2022 Datingverse Technologies</p>
					</div>
				</div>
			</div>
			<div className="toast" style={{display: 'none'}}>Coming &nbsp;&nbsp;Soon</div>
		</footer>
	)
}
