import { useEffect}      from 'react'
import { useLocation, Link }        from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

export const About = ( props ) => {
	const currHash = useSelector(state => state.menu)
	
	const dispatch = useDispatch()
	
	const location = useLocation()
	
	function link ( str ) {
		dispatch({ type: 'SET_MENU', data: str })
	}
	
	useEffect(() => {
		if ( location.hash !== currHash ) {
			dispatch({ type: 'SET_MENU', data: location.hash })
		}
		let wrap = document.querySelector('.accordion')
		let accordion = document.querySelectorAll('.accordion-item')
		
		for ( let i = 0; i < accordion.length; i ++ ) {
			accordion[i].addEventListener('mouseenter', e => {
				e = e || window.event
				if ( e.target.nodeName.toLowerCase() === 'li' ) {
					let cls = e.target.className.split(' ')
					
					switch( i ) {
						case 1:
							e.target.style.left = '310px'
							break
						case 2:
							e.target.style.left = 310 + 178 + 'px'
							break
						case 3:
							e.target.style.left = 310 + 178 * 2 + 'px'
							break
						case 4:
							accordion[1].style.left = 123 + 'px'
							accordion[2].style.left = 356 - 110 + 'px'
							accordion[3].style.left = 534 - 166 + 'px'
							
							break
						default:
							break
					}
					
					accordion.forEach(item => {
						item.className = 'accordion-item'
					})
					
					if ( ! cls.some(item => item === 'active') ) {
					}
					e.target.className = 'accordion-item active'
				}
			})
			accordion[i].addEventListener('mouseleave', e => {
				e = e || window.event
				if ( e.target.nodeName.toLowerCase() === 'li' ) {
					switch( i ) {
						case 1:
							e.target.style.left = '178px'
							break
						case 2:
							e.target.style.left = 356 + 'px'
							break
						case 3:
							e.target.style.left = 534 + 'px'
							break
						case 4:
							accordion[0].style.left = 0
							accordion[1].style.left = '178px'
							accordion[2].style.left = 356 + 'px'
							accordion[3].style.left = 534 + 'px'
							e.target.style.left = 712 + 'px'
							break
						default:
							e.target.style.left = '0'
							break
					}
				}
			})
		}
		wrap.addEventListener('mouseleave', e => {
			accordion.forEach(item => {
				item.className = 'accordion-item'
			})
			accordion[0].className = 'accordion-item active'
		})
		
		// video control
		const video = document.querySelector('#video')
		const volIcon = document.querySelector('.volume-icon')
		const playIcon = document.querySelector('.play-icon')
		
		volIcon.addEventListener('click', () => {
			video.muted = ! video.muted
			if ( volIcon.className.indexOf('open') > - 1 ) {
				volIcon.className = 'volume-icon'
			} else {
				volIcon.className = 'volume-icon open'
			}
		})
		video.addEventListener('click', () => {
			switchPlay(video, playIcon)
		})
		playIcon.addEventListener('click', () => {
			switchPlay(video, playIcon)
		})
		
	}, [])
	
	function switchPlay ( video, playIcon ) {
		if ( video.paused ) {
			video.play()
			playIcon.className = 'play-icon'
		} else {
			video.pause()
			playIcon.className = 'play-icon play'
		}
	}
	
	function showToast () {
		
		let toast = document.querySelector('.toast')
		if ( toast.style.display !== 'block' ) {
			toast.style.display = 'block'
			setTimeout(() => {
				toast.style.display = 'none'
			}, 2000)
		}
	}
	
	const isSafari = ( /Safari/.test(navigator.userAgent) && ! /Chrome/.test(navigator.userAgent) )
	
	return ( <div className="main-wrap">
		<div className="about-container relative">
			<ul className="pagination">
				<li className="pagination-bullet">
					<a href="/#datingverse" onClick={ () => link('#datingverse') } className={ currHash === '#datingverse' || currHash === '' ? 'page-scroll active' : 'page-scroll' } />
				</li>
				<li className="pagination-bullet">
					<a href="/#nft" onClick={ () => link('#nft') } className={ currHash === '#nft' ? 'page-scroll active' : 'page-scroll' } />
				</li>
				<li className="pagination-bullet">
					<a href="/#featured" onClick={ () => link('#featured') } className={ currHash === '#featured' ? 'page-scroll active' : 'page-scroll' } />
				</li>
				<li className="pagination-bullet">
					<a href="/#creators" onClick={ () => link('#creators') } className={ currHash === '#creators' ? 'page-scroll active' : 'page-scroll' } />
				</li>
				<li className="pagination-bullet">
					<a href="/#roadmap" onClick={ () => link('#roadmap') } className={ currHash === '#roadmap' ? 'page-scroll active' : 'page-scroll' } />
				</li>
				<li className="pagination-bullet">
					<a href="/#partners" onClick={ () => link('#partners') } className={ currHash === '#partners' ? 'page-scroll active' : 'page-scroll' } />
				</li>
			</ul>
			<div id="datingverse" className="container" style={ { paddingTop: '88px' } }>
				<div className="guide mb120">
					<div className="guide-wrap">
						<video id="video" className="mint-video mb50"
									 src={ require('../video/rock3.mp4') }
									 x5-video-player-fullscreen="false"
									 x5-playsinline="true"
									 playsInline
									 webkit-playsinline="true"
									 muted
									 autoPlay
									 loop />
						{/* <div className="mint-wrap-mask"/> */ }
						<div className="volume-icon" />
						<div className="play-icon" />
					</div>
					<div className="guide-content text-center mt10 mb40">
						<h2 className="page-title">Diamond Mint of Datingverse Genesis NFT</h2>
						<div className="text-center mt10">
							<p>WL -only, limited to 500</p>
							<p>-For OG: May 19, 12pm GMT - May 20, 12pm GMT</p>
							<p>-For WL: May 20, 12pm GMT - May 21, 12pm GMT</p>
						</div>
					</div>
					<Link to="/mint">
						<div className="guide-btn">MINT</div>
					</Link>
				</div>
				<div className="flex flex-btw mb50" style={ { alignItems: 'flex-end' } }>
					<div className="p1-left">
						<h1 className="p1-title relative">Datingverse</h1>
						<h3 className="p1-title-summary" style={ { marginBottom: '0' } }>A METAVERSE built FOR DATING</h3>
					</div>
					<Link to="/mint">
						<div className="p1-right flex flex-btw">
							<div className="p1-right-icon">
								<img src={ require('../img/about/diamond.png') } alt="" />
							</div>
							<div className="p1-right-content pointer" onClick={ showToast }
									 style={ { fontSize: isSafari ? '28px' : '30px' } }>
								<p>Diamond Mint Date</p>
								<p className="p1-right-content_date text-right flex flex-center_hor flex-end">
									<span className="arrow" />05 / 19 / 22</p>
							</div>
						</div>
					</Link>
				</div>
				<div className="grid grid-col-12 gap-4">
					<div className="col-span-3 img-wrap"><img src={ require('../img/about/b1.png') } alt="" /></div>
					<div className="col-span-3 img-wrap"><img src={ require('../img/about/b2.png') } alt="" /></div>
					<div className="col-span-3 img-wrap"><img src={ require('../img/about/b3.png') } alt="" /></div>
					<div className="col-span-3 img-wrap"><img src={ require('../img/about/b4.png') } alt="" /></div>
				</div>
				<div className="p1-content mt60 mb120">
					<p>Datingverse&nbsp; is&nbsp; a&nbsp; social&nbsp; metaverse&nbsp; for&nbsp; dating&nbsp; based&nbsp; on&nbsp; WEB3 &nbsp;and&nbsp; VR &nbsp;technology. &nbsp;As&nbsp; an
						 exploration of SocialFi, Datingverse sets up a “dating to earn” mechanism, while allowing fans
						 to build interactive relationships with content creators through the subscription service.
					</p>
				</div>
			</div>
			<div id="" className="feature container mt60">
				<h2 className="p1-title-summary">IN DATINGVERSE YOU CAN</h2>
				<div className="scroll-wrap">
					<ul className="accordion lineH_0">
						<li className="accordion-item active" style={ { left: '0' } } data-in="1">
							<div className="accordion-item-content">
								<img src={ require('../img/about/fea1.png') } alt="" className="accordion-item-img" />
								<div className="content mt20">
									<div className="accordion-item-content_title mb20">Owning Dating Land</div>
									<div className="accordion-item-content_des">
										· Based on the ERC1155 <br />
										· Use the space by yourself or rent it for revenue <br />
										· Create and save up Dating Space scene templates <br />
										· Own content subscription channel<br />
									</div>
									<a href="/land">
										<div className="accordion-item-content_act flex flex-center flex-end flex-center_hor">
											<p>LEARN MORE</p>
											<span className="act-icon" />
										</div>
									</a>
								</div>
							</div>
						</li>
						<li className="accordion-item" style={ { left: '178px' } }>
							<div className="accordion-item-content">
								<img src={ require('../img/about/fea2.png') } alt="" className="accordion-item-img" />
								<div className="content mt20">
									<div className="accordion-item-content_title mb20">Creat Dating Space</div>
									<div className="accordion-item-content_des">
										· An interactive dating space in the Datingverse <br />
										· Created on a temporary basis each time <br />
										· The 3D/VR version will be built on unity3 and the next version will be built on unity5
									</div>
									<a href="/space">
										<div className="accordion-item-content_act flex flex-center flex-end flex-center_hor">
											<p>LEARN MORE</p>
											<span className="act-icon" />
										</div>
									</a>
								</div>
							</div>
						</li>
						<li className="accordion-item" style={ { left: '356px' } }>
							<div className="accordion-item-content">
								<img src={ require('../img/about/fea3.png') } alt="" className="accordion-item-img" />
								<div className="content mt20">
									<div className="accordion-item-content_title mb20">Happy dating</div>
									<div className="accordion-item-content_des">
										· Guaranteed anonymity and privacy through WEB3 technology <br />
										· Brings more immersive realism to the remote dating experience through 3D/VR technology.
									</div>
									<a href="/dating">
										<div className="accordion-item-content_act flex flex-center flex-end flex-center_hor">
											<p>LEARN MORE</p>
											<span className="act-icon" />
										</div>
									</a>
								</div>
							</div>
						</li>
						<li className="accordion-item" style={ { left: '534px' } }>
							<div className="accordion-item-content">
								<img src={ require('../img/about/fea4.png') } alt="" className="accordion-item-img" />
								<div className="content mt20">
									<div className="accordion-item-content_title mb20">Earn $DVC</div>
									<div className="accordion-item-content_des">
										· Content Creators can earn revenue through fan subscriptions<br />
										· 3D/VR Producer can earn revenue by making and selling 3D/VR scenes<br />
										· Share in the growth of Datingverse by earning/holding $DVC
									</div>
									<a href="/dvc">
										<div className="accordion-item-content_act flex flex-center flex-end flex-center_hor">
											<p>LEARN MORE</p>
											<span className="act-icon" />
										</div>
									</a>
								</div>
							</div>
						</li>
						<li className="accordion-item" style={ { left: '712px' } }>
							<div className="accordion-item-content">
							</div>
							<div className="accordion-des">DATINGVERSE APP COMING SOON</div>
						</li>
					</ul>
				</div>
			</div>
			<div id="nft" className="container pt120">
				<div className="flex flex-btw flex-center_hor mb60">
					<h1 className="p1-title relative">GENESIS NFT</h1>
					<Link to="/mint">
						<div className="p1-right flex flex-btw flex-center_hor pointer" onClick={ showToast }>
							<img className="sm-hide" src={ require('../img/about/diamond.png') } alt=""
									 style={ { width: '32px', height: '32px' } } />
							<p className="p1-right-content flex flex-end flex-center_hor">
								<span className="sm-hide">Diamond&nbsp;</span><span>Mint Here</span><span className="arrow no-r" />
							</p>
						</div>
					</Link>
				</div>
				<div className="p1-content mb60" style={ { width: '100%' } }>
					In order to raise more early R&D funds and to find our early active participants and supporters, we will
					release a collection of well-designed 5000 NFTs crafted by some talented artists.&nbsp; These NFTs will be
					built
					on the Ethernet mainnet via the&nbsp; ERC-721A &nbsp;protocol.&nbsp; The funds raised from the Genesis NFT
					mint&nbsp; will&nbsp; be&nbsp; mainly
					used&nbsp; for&nbsp; project &nbsp;R&D,&nbsp; with a small portion of the remaining funds being used for
					promotional purposes and to
					purchase some collaborative resources upfront.
				</div>
				<div className="grid grid-col-12 mb60 sm-hide">
					<div className="col-span-3 text-center lineH_0">
						<img src={ require('../img/nft/NFT1.png') } alt="" className="nft-img" />
						<p className="nft-price"> 5000 </p>
						<p className="nft-des">&nbsp;</p>
						<p className="nft-type">QUANTITY</p>
					</div>
					<div className="col-span-3 text-center lineH_0">
						<img src={ require('../img/nft/NFT2.png') } alt="" className="nft-img" />
						<p className="nft-price"> 0.1 ETH </p>
						<p className="nft-des">&nbsp;</p>
						<p className="nft-type">MINT PRICE</p>
					</div>
					<div className="col-span-3 text-center lineH_0">
						<img src={ require('../img/nft/NFT3.png') } alt="" className="nft-img" />
						<p className="nft-price"> 5% </p>
						<p className="nft-des">All to the treasury</p>
						<p className="nft-type">ROYALTIES</p>
					</div>
					<div className="col-span-3 text-center lineH_0">
						<img src={ require('../img/nft/NFT4.png') } alt="" className="nft-img" />
						<p className="nft-price"> 200 </p>
						<p className="nft-des">Internal awards and gifts</p>
						<p className="nft-type">RESERVED NFT</p>
					</div>
				</div>
				<div className="sm-show mb50">
					<div className="grid grid-col-12 gap-18">
						<div className="col-span-6 lineH_0"><img src={ require('../img/nft/NFT1.png') } alt=""
																										 className="nft-img" /></div>
						<div className="col-span-6 lineH_0"><img src={ require('../img/nft/NFT2.png') } alt=""
																										 className="nft-img" /></div>
						<div className="col-span-6 lineH_0"><img src={ require('../img/nft/NFT3.png') } alt=""
																										 className="nft-img" /></div>
						<div className="col-span-6 lineH_0"><img src={ require('../img/nft/NFT4.png') } alt=""
																										 className="nft-img" /></div>
					</div>
					<div className="grid grid-col-12 gap-18 nft">
						<div className="col-span-6">
							<p className="nft-price"> 5000 </p>
							<p className="nft-des">&nbsp;</p>
							<p className="nft-type">QUANTITY</p>
						
						</div>
						<div className="col-span-6">
							<p className="nft-price"> 0.1 ETH </p>
							<p className="nft-des">&nbsp;</p>
							<p className="nft-type">MINT PRICE</p>
						</div>
						<div className="col-span-6">
							<p className="nft-price"> 5% </p>
							<p className="nft-des">All to the treasury</p>
							<p className="nft-type">ROYALTIES</p>
						</div>
						<div className="col-span-6">
							<p className="nft-price"> 200 </p>
							<p className="nft-des">Internal awards and gifts</p>
							<p className="nft-type">RESERVED NFT</p>
						</div>
					</div>
				</div>
				<div className="holder mb60">
					<div className="flex flex-center_hor flex-btw">
						<div className="text-center lineH_0">
							<img src={ require('../img/nft/icon1.png') } alt="" style={ { width: '189px' } } />
							<p className="holder-des mt20">HOLDER BENEFITS</p>
						</div>
						<div className="holder-content">
							<div className="sm-show mb30">
								Genesis NFT holders are Datingverse's most valuable asset. We hope that every Genesis NFT holder will
								hold and serve as an ambassador of Datingverse for a long time, promoting and introducing this exciting
								program to as many people as possible. We have designed the following benefits for Genesis NFT, and will
								be adding more valuable benefits as the project moves forward:
							</div>
							1. Unique Genesis symbol<br />
							2. Stake to get $DVC airdrop (5% of total $DVC)<br />
							3. Whitelist for Dating Land pre-sale<br />
							4. Participation in the DAO poll
						</div>
					</div>
				</div>
				<div className="guarantee mb120">
					<div className="flex flex-center_hor flex-btw">
						<div className="text-center lineH_0" style={ { minWidth: '200px' } }>
							<img src={ require('../img/nft/icon2.png') } alt="" style={ { width: '171px' } } />
							<p className="guarantee-des mt20">PRICE GUARANTEE</p>
						</div>
						<div className="guarantee-content">
							1.&nbsp;After the public sale ends, all Eth obtained by mint will be credited to the smart contract<br />
							2.&nbsp;The refund period is 10 days<br />
							3.&nbsp;The refund price equals the mint price<br />
							4.&nbsp;The refunded NFT will be automatically sent to the black hole address for burning through the
							smart contract<br />
							5.&nbsp;The total amount of $DVC airdrops remains unchanged. After refund, the airdrops of each NFT will
							increase<br />
							6.&nbsp;The gas fee for mint and refund will be borne by the holder
						</div>
					</div>
				</div>
				<div className="audit lineH_0">
					<h2 className="p1-title-summary mb50">SECURITY AUDIT REPORT </h2>
					<a target="_blank"
						 href="https://github.com/EtherAuthority/Portfolio/blob/main/Smart%20Contract%20Audits/Datingverse-Token-Smart-Contracts-Security-Audit-Report-REVISED.pdf">
						<img className="sm-show" src={ require('../img/mint/audit.jpg') } alt="" />
					</a>
					<div className="flex flex-center_hor sm-hide">
						<div className="audit-left">
							<a target="_blank"
								 href="https://github.com/EtherAuthority/Portfolio/blob/main/Smart%20Contract%20Audits/Datingverse-Token-Smart-Contracts-Security-Audit-Report-REVISED.pdf">
								<img src={ require('../img/mint/audit.jpg') } alt="" />
							</a>
						</div>
						<div className="audit-right">
							<h4 className="audit-right-title mb20">Claimed Smart Contract Features</h4>
							<p className="audit-right-title mid">Claimed Feature Detail</p>
							<p className="audit-right-title sm">Tokenomics:</p>
							<div className="page-summary mb30">
								<p>Name: Datingverse</p>
								<p>Symbol: Datingverse</p>
								<p>Maximum Total Diamond Supply: 500</p>
								<p>Maximum Total Supply: 5000</p>
								<p>Maximum Mint: 2</p>
								<p>Price: 0.1 ether</p>
								<p>Refund Guarantee Active Function</p>
								<p>Refunded NFTs will be burned</p>
								<p>The owner can withdraw ether from the contract after the Refund Guarantee period (which is 10 days)
									 is over.</p>
							</div>
							<h4 className="audit-right-title mid mb20" style={ { fontWeight: '400' } }>Our Observation</h4>
							<h2 className="p1-title-summary">YES, This is valid.</h2>
						</div>
					</div>
				</div>
			</div>
			<div id="featured" className="featured container pt148">
				<h2 className="p1-title-summary">
					<a className="flex flex-center_hor" href="https://datingverse.medium.com" target="_blank">FEATURED ON <span
						className="featured-link"></span></a>
				</h2>
				<a href="https://www.digitaljournal.com/pr/datingverse-a-metaverse-built-for-dating" target="_blank"
					 className="featured-item">
					<div className="featured-img">
						<img className="" src={ require('../img/feature/1.png') } alt="" style={ { height: '58px' } } />
					</div>
					<div className="featured-content">
						<p className="featured-title">Datingverse : A Metaverse built For Dating</p>
						<p className="featured-des">Metaverse is a new term used among the web. People can invest in it, play games,
																				and of course
							<span className="sm-hide"><br /> socialize. People have been wondering about it for a long time, but now we are here to provide some …</span>
						</p>
					</div>
				</a>
				<a
					href="https://www.benzinga.com/pressreleases/22/04/ab26648267/dating-in-metaverse-will-eventually-replace-dating-in-real-world"
					target="_blank" className="featured-item">
					<div className="featured-img">
						<img className="" src={ require('../img/feature/2.png') } alt="" style={ { height: '58px' } } />
					</div>
					<div className="featured-content">
						<p className="featured-title">Dating in metaverse will eventually replace dating in real world</p>
						<p className="featured-des">In the last decade, the entire dating space has evolved rapidly. With the advent
																				of apps such as Tinder and Bumble, the dating culture wasn’t merely an in-person
																				social exercise but became a curated way …</p>
					</div>
				</a>
				<a href="https://www.digitaljournal.com/pr/datingverse-find-partners-in-the-metaverse" target="_blank"
					 className="featured-item">
					<div className="featured-img">
						<img className="" src={ require('../img/feature/1.png') } alt="" style={ { height: '58px' } } />
					</div>
					<div className="featured-content">
						<p className="featured-title">Datingverse: Find Partners in the Metaverse</p>
						<p className="featured-des">Our dating culture has changed radically. Traditionally, dating is done with the
																				help of some parties or through friend introduction. But not nowadays, we first
																				get to know each other with the help of ...</p>
					</div>
				</a>
				<a
					href="https://www.benzinga.com/pressreleases/22/04/ab26595125/datingverse-is-revolutionizing-the-private-dating-and-interaction-experience"
					target="_blank" className="featured-item">
					<div className="featured-img">
						<img className="" src={ require('../img/feature/2.png') } alt="" style={ { height: '58px' } } />
					</div>
					<div className="featured-content">
						<p className="featured-title">Datingverse is revolutionizing the private dating and interaction
																					experience</p>
						<p className="featured-des">What is Datingverse Datingverse is a communal metaverse devoted to dating that
																				is developed using Web3 and virtual reality technology to provide an immersive
																				experience. Through the subscription …</p>
					</div>
				</a>
				<a href="https://finance.yahoo.com/news/datingverse-explains-dating-earn-161100056.html" target="_blank"
					 className="featured-item">
					<div className="featured-img">
						<img className="" src={ require('../img/feature/4.png') } alt="" style={ { height: '58px' } } />
					</div>
					<div className="featured-content">
						<p className="featured-title">Datingverse Explains What is “Dating to Earn”</p>
						<p className="featured-des">As a pioneer in the Metaverse, Datingverse emerges as a breakthrough initiative
																				to create a comprehensive ecosystem where individuals worldwide may connect,
																				interact, have fun, and make ...</p>
					</div>
				</a>
			</div>
			<div id="creators" className="creators container pt148">
				<h2 className="p1-title-summary">CREATORS <span className="sm-hide"> IN DATINGVERSE</span></h2>
				<h4 className="sm-show page-title mid-title mt60 mb60">Contact us and
					<a href="https://twitter.com/datingverse" className="color-orange" target="_blank"> JOIN NOW!</a></h4>
				<div className="page-summary mb60">
					<p>The following creators are already joined the Datingverse community. </p>
					<p className="mt30"></p>
					<p>The first 50 creators to be certified will receive a FREE Dating Land!</p>
				</div>
				<div className="creators-grid">
					<div className="grid grid-col-12">
						<div className="col-span-3 text-center">
							<a href="https://twitter.com/coser_BOSS" target="_blank">
								<img src={ require('../img/creator/1.png') } className="creators-avatar" alt="" />
								<div className="creators-nickname flex flex-center_ver flex-center_hor ">
									<span>@coser_BOSS</span>
									<span className="iconfont icon-tw link-icon" />
								</div>
							</a>
						</div>
						<div className="col-span-3 text-center">
							<img src={ require('../img/creator/2.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@Hormaks</span>
							</div>
						</div>
						<div className="col-span-3 text-center">
							<img src={ require('../img/creator/3.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@Agnes</span>
							</div>
						</div>
						<div className="col-span-3 text-center">
							<img src={ require('../img/creator/4.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@Anastasiya</span>
							</div>
						</div>
						<div className="col-span-12 sm-hide">
							<div className="flex flex-btw" style={ { padding: '0 218px' } }>
								<div className="col-span-3 text-center">
									<a href="https://twitter.com/polyannie01" className="" target="_blank">
										<img src={ require('../img/creator/5.png') } className="creators-avatar" alt="" />
										<div className="creators-nickname flex flex-center_ver flex-center_hor ">
											<span>@polyannie01</span>
											<span className="iconfont icon-tw link-icon" />
										</div>
									</a>
								</div>
								<div className="col-span-3 text-center">
									<img src={ require('../img/creator/6.png') } className="creators-avatar" alt="" />
									<div className="creators-nickname flex flex-center_ver flex-center_hor ">
										<span>@valentinablinov</span>
									</div>
								</div>
								<div className="col-span-3 text-center">
									<img src={ require('../img/creator/7.png') } className="creators-avatar" alt="" />
									<div className="creators-nickname flex flex-center_ver flex-center_hor ">
										<span>@desibae</span>
									</div>
								</div>
							</div>
						</div>
						<div className="sm-show col-span-3 text-center">
							<a href="https://twitter.com/polyannie01" className="" target="_blank">
								<img src={ require('../img/creator/5.png') } className="creators-avatar" alt="" />
								<div className="creators-nickname flex flex-center_ver flex-center_hor ">
									<span>@polyannie01</span>
									<span className="iconfont icon-tw link-icon" />
								</div>
							</a>
						</div>
						<div className="sm-show col-span-3 text-center">
							<img src={ require('../img/creator/6.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@valentinablinov</span>
							</div>
						</div>
						<div className="sm-show col-span-3 text-center">
							<img src={ require('../img/creator/7.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@desibae</span>
							</div>
						</div>
						
						<div className="col-span-3 text-center">
							<img src={ require('../img/creator/8.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@lisavgl</span>
							</div>
						</div>
						<div className="col-span-3 text-center">
							<img src={ require('../img/creator/9.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@sindy miranda</span>
							</div>
						</div>
						<div className="col-span-3 text-center">
							<img src={ require('../img/creator/10.png') } className="creators-avatar" alt="" />
							<div className="creators-nickname flex flex-center_ver flex-center_hor ">
								<span>@Janey</span>
							</div>
						</div>
						<div className="col-span-3 text-center">
							<a href="https://discord.gg/datingverse" target="_blank" className="pointer">
								<img src={ require('../img/creator/join.png') } className="creators-avatar" alt="" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div id="roadmap" className="roadmap container pt148 sm-hide">
				<h2 className="p1-title-summary">ROADMAP</h2>
				<div className="flex flex-btw">
					<div className="step">
						<div className="step-arrow">Q1/2022</div>
						<div className="step-wrap">
							<div className="step-wrap_title">Community</div>
							<p>Official Website</p>
							<p>twitter, discord</p>
							<p>Instagram</p>
							<p>Lite Paper</p>
							<div className="step-wrap_title mt20">Genesis NFT</div>
							<p>WhiteList distribution</p>
							<p>Early demos</p>
						</div>
					</div>
					<div className="step">
						<div className="step-arrow">Q2/2022</div>
						<div className="step-wrap long">
							<div className="step-wrap_title">Genesis NFT</div>
							<p>WhiteList distribution</p>
							<p>Diamond Mint</p>
							<p>Genesis Mint</p>
							<p>Refunds allowed and</p>
							<p>Burned</p>
							<div className="step-wrap_title mt20">App Preview</div>
							<p>Basic function</p>
							<p>Dating Land</p>
							<p>Subscription</p>
							<p>Content NFT</p>
							<p>Dating Room</p>
							<p>Hardware Integration</p>
						</div>
					</div>
					<div className="step">
						<div className="step-arrow">Q3/2022</div>
						<div className="step-wrap long">
							<div className="step-wrap_title">
								Dating Land <br />Public Sale Round 1
							</div>
							<div className="step-wrap_title">Datingverse Coin <br />$DVC</div>
							<p>Tokenomics</p>
							<p>ILO</p>
							<p>Genesis NFT stake</p>
							<p>$DVC stake</p>
							<div className="step-wrap_title">
								Dating Land
							</div>
							<p>Airdrop</p>
							<p>Presale</p>
							<p>Public Sale Round 1</p>
							<div className="step-wrap_title">
								App Release
							</div>
						</div>
					</div>
					<div className="step">
						<div className="step-arrow">Q4/2022</div>
						<div className="step-wrap">
							<div className="step-wrap_title">
								3D/VR App Preview
							</div>
							<div className="step-wrap_title">
								Dating Land <br />
								Public Sale Round 2
							</div>
						</div>
					</div>
					<div className="step">
						<div className="step-arrow">Q1/2023</div>
						<div className="step-wrap">
							<div className="step-wrap_title">
								3D/VR App Release
							</div>
							<div className="step-wrap_title">
								Datingvese DAO
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="m-roadmap" className="sm-show road container pt148">
				<h1 className="default-title">Roadmap</h1>
				<div className="road-content">
					<div className="col-span-6 text-right">
						<div className="step-wrap step-wrap_left">
							<div className="btn-step">Q2/2022</div>
							<h3 className="sec-title">Genesis NFT</h3>
							<ul className="">
								<li>WhiteList distribution</li>
								<li>Diamond Mint</li>
								<li>Genesis Mint</li>
								<li>Refunds allowed and burned</li>
							</ul>
							<h3 className="sec-title">App Preview</h3>
							<ul className="">
								<li>Basic function</li>
								<li>Dating Land</li>
								<li>Subscription</li>
								<li>Content NFT</li>
								<li>Dating Room</li>
								<li>Hardware Integration</li>
							</ul>
						</div>
						<div className="step-wrap step-wrap_left">
							<div className="btn-step">Q4/2022</div>
							<h3 className="sec-title">3D/VR App Release</h3>
							<h3 className="sec-title">Dating Land <br />Public Sale Round 2</h3>
						</div>
						<div className="step-wrap step-wrap_left">
							<div className="btn-step">Q1/2023</div>
							<h3 className="sec-title">3D/VR App Release</h3>
							<h3 className="sec-title">Datingvese DAO</h3>
						</div>
					</div>
					<div className="col-span-6">
						<div className="step-wrap">
							<div className="btn-step">Q1/2022</div>
							<h3 className="sec-title">Community</h3>
							<ul className="">
								<li>Official Website</li>
								<li>twitter discord Instagram</li>
								<li>Lite Paper</li>
							</ul>
							<h3 className="sec-title" style={ { marginTop: '15px' } }>Genesis NFT</h3>
							<ul className="">
								<li>WhiteList distribution</li>
								<li>Early demos</li>
							</ul>
						</div>
						<div className="step-wrap">
							<div className="btn-step">Q3/2022</div>
							<h3 className="sec-title">Dating Land <br />Public Sale Round 1</h3>
							<h3 className="sec-title">Datingverse Coin $DVC</h3>
							<ul className="">
								<li>Tokenomics</li>
								<li>ILO</li>
								<li>Genesis NFT stake</li>
								<li>$DVC stake</li>
							</ul>
							<h3 className="sec-title" style={ { marginTop: '15px' } }>Dating Land</h3>
							<ul className="">
								<li>Airdrop</li>
								<li>Presale</li>
								<li>Public Sale Round 1</li>
							</ul>
							<h3 className="sec-title" style={ { marginTop: '15px' } }>App Release</h3>
						</div>
					</div>
				</div>
			</div>
			
			<div id="partners" className="sm-hide partners container pt148">
				<h2 className="p1-title-summary">PARTNERS</h2>
				<div className="partners-content">
					<a href="https://www.vive.com/" target="_blank">
						<img src={ require('../img/partner/partners-1.png') } alt="" />
					</a>
					<a href="https://www.xsens.cn/">
						<img src={ require('../img/partner/partners-2.png') } alt="" />
					</a>
					<a href="https://www.reallusion.com/character-creator/" target="_blank">
						<img src={ require('../img/partner/partners-3.png') } alt="" />
					</a>
					<a href="https://www.daz3d.com" target="_blank">
						<img src={ require('../img/partner/partners-4.png') } alt="" />
					</a>
					<a href="http://www.szgalaxy.com/ " target="_blank">
						<img src={ require('../img/partner/partners-5.png') } alt="" />
					</a>
				</div>
				<div className="partners-content">
					<a href="https://www.bhaptics.com/" target="_blank">
						<img src={ require('../img/partner/partners-6.png') } alt="" style={ { maxHeight: '80px' } } />
					</a>
					<a href="https://www.renderosity.com/" target="_blank">
						<img src={ require('../img/partner/partners-7.png') } alt="" style={ { maxHeight: '80px' } } />
					</a>
				</div>
			</div>
			<div id="m-partners" className="sm-show container mb120 ab-p5">
				<h1 className="default-title relative mb60">Partners</h1>
				<div className="flex">
					<a className="img-left" href="http://www.szgalaxy.com/ " target="_blank">
						<img src={ require('../img/partner/partners-5.png') } alt="" />
					</a>
					<a className="flex-item" href="https://www.bhaptics.com/" target="_blank">
						<img src={ require('../img/partner/partners-6.png') } alt="" style={ { maxHeight: '80px' } } />
					</a>
				</div>
				<div className="flex">
					<a className="img-left" href="https://www.xsens.cn/">
						<img src={ require('../img/partner/partners-2.png') } alt="" />
					</a>
					<a className="flex-item" href="https://www.renderosity.com/" target="_blank">
						<img src={ require('../img/partner/partners-7.png') } alt="" style={ { maxHeight: '80px' } } />
					</a>
				</div>
				<div className="flex">
					<a className="img-left" href="https://www.vive.com/" target="_blank">
						<img src={ require('../img/partner/partners-1.png') } alt="" />
					</a>
					<a className="img-left" href="https://www.reallusion.com/character-creator/" target="_blank">
						<img src={ require('../img/partner/partners-3.png') } alt="" />
					</a>
					<a className="img-left" href="https://www.daz3d.com" target="_blank">
						<img src={ require('../img/partner/partners-4.png') } alt="" />
					</a>
				</div>
			</div>
		</div>
		<div className="toast" style={ { display: 'none' } }>Coming &nbsp;&nbsp;Soon</div>
	</div> )
}
