import {useLocation}             from 'react-router-dom'
import { useEffect }             from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { message }               from './messageBox'

const { ethereum } = window;

export const Header = (props) => {
	const currHash = useSelector(state => state.menu)
	const currAccessAccount = useSelector(state => state.accessAccount)
	const dispatch = useDispatch()
	
	const location = useLocation()
	
	function link ( str ) {
		dispatch({type:'SET_MENU',data: str})
	}
	
	function openMetaMaskUrl(url) {
		const a = document.createElement("a");
		a.href = url;
		a.target = "_self";
		document.body.appendChild(a);
		a.click();
		a.remove();
	}
	
	const checkWalletIsConnected = async () => {
		if ( ! ethereum ) {
			return
		}
		const accounts = await ethereum.request({ method: 'eth_accounts' })
		if ( accounts.length !== 0 ) {
			const account = accounts[0]
			dispatch({type:'SET_ACCOUNT',data:account})
		} else {
			console.log('No authorized account found')
		}
	}
	async function LinkMetaMask(){
		if ( ! ethereum ) {
			if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
				let urlObj = window.location
				openMetaMaskUrl("https://metamask.app.link/dapp/datingverse.org"+urlObj.pathname+urlObj.hash);
			}else {
				message.error('Error','Make sure you have Metamask installed!')
			}
		}
		
		const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
		if ( accounts.length !== 0 ) {
			const account = accounts[0]
			dispatch({type:'SET_ACCOUNT',data:account})
		} else {
			console.log('No authorized account found')
		}
	}

	function setHeight(){
		var dom = document.querySelector('.nav')
		var op = document.querySelector('.m-open')
		var cl = document.querySelector('.m-close')
		if ( dom.style.height === '100vh' ) {
			dom.style.height = ''
			op.style.display = 'block'
			cl.style.display = 'none'
			document.body.classList = ''
		}else {
			dom.style.height = '100vh'
			op.style.display = 'none'
			cl.style.display = 'block'
			document.body.classList = 'hidden'
			document.addEventListener("touchmove",()=>{},false)
		}
	}
	
	function closeMobileNav(){
		var dom = document.querySelector('.nav')
		var op = document.querySelector('.m-open')
		var cl = document.querySelector('.m-close')
		
		dom.style.height = ''
		op.style.display = 'block'
		cl.style.display = 'none'
		document.body.classList = ''
	}
	
	useEffect(()=>{
		if ( ethereum ) {
			checkWalletIsConnected()
			ethereum.on('accountsChanged', ( accounts )=> {
				dispatch({type:'SET_ACCOUNT',data:accounts[0]})
				closeMobileNav()
			})
			ethereum.on('chainChanged', (chainId) => {
				dispatch({type:'SET_CHAINID',data: chainId})
				closeMobileNav()
			});
			ethereum.on('connect', (res) => {
				dispatch({type:'SET_CHAINID',data: res.chainId})
				closeMobileNav()
			});
		}
	},[])
	
	return(
		<nav className="nav">
			<div className="navbar-nav mx-auto">
				<div className="flex flex-btw flex-center_hor">
					<a href="./" className='logo-wrap' style={{lineHeight:'0'}}>
						<img className="logo" src={require('../img/logo.png')} alt="" />
						<img className="logo2" src={require('../img/logo2.png')} alt="" />
					</a>
					<ul className="mr20">
						<li>
							<a href="/#datingverse" className={currHash!=='#nft' && location.pathname!== "/mint" ?'page-scroll active':'page-scroll'} onClick={()=>link('#datingverse')}>Home</a>
						</li>
						<li>
							<a href="/#nft" className={location.pathname=== "/mint" || currHash==='#nft'?'page-scroll active':'page-scroll'} onClick={()=>link('#nft')}>Genesis NFT</a>
						</li>
						<li>
							<a href="https://docs.datingverse.org/" target="_blank" className="page-scroll" rel="noopener noreferrer">Litepaper</a>
						</li>
						<li>
							<a href="https://docs.datingverse.org/extra/faq" target="_blank" className="page-scroll" rel="noopener noreferrer">FAQ</a>
						</li>
						<li className="flex flex-center" style={{marginLeft:"35px"}}>
							<a href="https://twitter.com/datingverse" rel="noopener noreferrer" className="iconfont icon-tw" target="_blank"/>
							<a href="https://discord.gg/datingverse" rel="noopener noreferrer" className="iconfont icon-dis" target="_blank"/>
							<a href="https://datingverse.medium.com" rel="noopener noreferrer" className="iconfont icon-med" target="_blank"/>
							<a href="https://www.instagram.com/datingverse" rel="noopener noreferrer" className="iconfont icon-ins" target="_blank"/>
							<a href="mailto:hello@datingverse.org" rel="noopener noreferrer" className="iconfont icon-mail"/>
							<a href="https://t.me/datingverse" rel="noopener noreferrer" className="iconfont icon-telegram" target="_blank"/>
							<a href="https://opensea.io/collection/datingverse" rel="noopener noreferrer" className="iconfont icon-boat" target="_blank"/>
						</li>
					</ul>
				</div>
				<button className="cta-wallet">
					{currAccessAccount ? <span>{currAccessAccount.substring(0,5)}...{currAccessAccount.substring(currAccessAccount.length-4,currAccessAccount.length)}</span> : <span onClick={()=>LinkMetaMask()} style={{cursor:'pointer'}}>Connect</span>}
				</button>
			</div>
			<div className="m-navbar flex flex-btw flex-center_hor">
				<a href="/" style={{lineHeight:'0'}}>
					<img src={require('../img/m-logo.png')} alt="" />
					<img className="logo2" src={require('../img/logo2.png')} alt="" />
				</a>
				<p className="m-open" onClick={setHeight}/>
				<p className="m-close" onClick={setHeight}/>
			</div>
			<ul className="m-navbar-nav">
				<li onClick={setHeight}>
					<a href="/#datingverse" className="page-scroll" >Datingverse</a>
					<ul>
						<li><a href="/#nft" className="page-scroll"># Genesis NFT</a></li>
						<li><a href="/#featured" className="page-scroll"># Featured On</a></li>
						<li><a href="/#creators" className="page-scroll"># Creators</a></li>
						<li><a href="/#m-roadmap" className="page-scroll"># Roadmap</a></li>
						<li><a href="/#m-partners" className="page-scroll"># Partners</a></li>
					</ul>
				</li>
				<li>
					<a href="https://docs.datingverse.org/" target="_blank" className="page-scroll" rel="noopener noreferrer">Litepaper</a>
				</li>
				<li >
					<a href="https://twitter.com/datingverse" target="_blank" rel="noopener noreferrer" className="flex flex-btw flex-center_hor">
						Twitter <span className="iconfont icon-tw"/>
					</a>
				</li>
				<li >
					<a className="flex flex-btw flex-center_hor" target="_blank" href="https://discord.gg/datingverse" rel="noopener noreferrer" >
						Discord
						<span className=" iconfont icon-dis"/>
					</a>
				</li>
				<li >
					<a className="flex flex-btw flex-center_hor" target="_blank" href="https://datingverse.medium.com" rel="noopener noreferrer" >
						Medium
						<span className=" iconfont icon-med"/>
					</a>
				</li>
				<li >
					<a className="flex flex-btw flex-center_hor" target="_blank" href="https://www.instagram.com/datingverse" rel="noopener noreferrer" >
						Instagram
						<span className=" iconfont icon-ins"/>
					</a>
				</li>
				<li >
					<a className="flex flex-btw flex-center_hor" href="mailto:hello@datingverse.org" rel="noopener noreferrer" >
						Email
						<span className="iconfont icon-mail"/>
					</a>
				</li>
				<li >
					<a className="flex flex-btw flex-center_hor" target="_blank" href="https://t.me/datingverse" rel="noopener noreferrer" >
						Telegram
						<span className="iconfont icon-telegram"/>
					</a>
				</li>
				<li >
					<a href="https://opensea.io/collection/datingverse"  className="flex flex-btw flex-center_hor" rel="noopener noreferrer" target="_blank">
						OpenSea
						<span className="iconfont icon-boat"/>
					</a>
				</li>
				<li>
					<div className="btn-connect">{currAccessAccount ? <span>{currAccessAccount.substring(0,5)}...{currAccessAccount.substring(currAccessAccount.length-4,currAccessAccount.length)}</span> : <span onClick={()=>LinkMetaMask()} style={{display:'block'}}>Connect&nbsp;&nbsp; Wallet</span>}</div>
				</li>
				<li className="m-cr">Copyright © 2022 Datingverse Technologies</li>
			</ul>
		</nav>
	)
}
