import req      from './request'
import contract from '../components/web3/contract'

const etherApiKey = 'UEBNS6DF2KS6RYXYAMMSNDWK59I8MVDKT8'
// TODO update api key for openSea
const openSeaApiKey = 'UEKJJGSFWBJ'

const _env = process.env.NODE_ENV

const accessUrl = _env === 'development' ? 'http://101.132.98.0:8080' : 'https://api.datingverse.org'

const etherscanUrl = _env === 'development' ? 'https://api-rinkeby.etherscan.io/api' : 'https://api.etherscan.io/api'

const openSeaUrl = _env === 'development' ? 'https://testnets-api.opensea.io/api/v1' : 'https://api.opensea.io/api/v1/assets'

export function getAccess ( account ) {
	return req({
		// method: 'GET',
		method: 'POST',
		url: accessUrl+'/info',
		// params: {
		// 	address: account
		// }
		data:{
			address: account
		}
	})
}

export function getProcess ( account ) {
	return req({
		// method: 'GET',
		method: 'POST',
		url: accessUrl+'/process',
		// params: {
		// 	address: account
		// }
		data:{
			address: account
		}
	})
}


export function getTokenIds ( userAccount ) {
	return req({
		url: etherscanUrl,
		params: {
			module: 'account',
			action: 'tokennfttx',
			contractaddress: contract.address,
			address: userAccount,
			page: 1,
			offset: 100,
			startblock: 0,
			sort: 'asc',
			apikey: etherApiKey
		}
	})
}

export function getTokenSupply(){
	return req({
		url: etherscanUrl,
		params: {
			module: 'stats',
			action: 'tokensupply',
			contractaddress: contract.address,
			apikey: etherApiKey
		}
	})
}

/**
 * @param {String} data.owner
 * @param {String} data.asset_contract_address
 * @param {String} data.order_by
 * */
export function getAssetsByAccount ( data ) {
	let headers
	if ( _env === 'development' ) {
		headers = null
	} else {
		headers = {
			'X-API-KEY': openSeaApiKey
		}
	}
	return req({
		headers: headers,
		url: openSeaUrl + '/assets',
		params: data
	})
}
