import { useEffect, useState }                        from 'react'
import { ethers, utils }                              from 'ethers'
import contract                                       from './contract'
import { getAccess, getAssetsByAccount, getTokenIds } from '../../tools/api'
import { message }                                    from '../messageBox'
import { useDispatch, useSelector }                   from 'react-redux'

const contractAddress = contract.address
const abi = contract.abi

const price = 0.1

let _provider
const { ethereum } = window

export const ConnectWallet = ( props ) => {
	if ( ethereum ) _provider = new ethers.providers.Web3Provider(ethereum)
	const _props = props.data
	const currAccessAccount = useSelector(state => state.accessAccount)
	const currChainId = useSelector(state => state.currentChainId)
	const dispatch = useDispatch()
	const [ Balance, setBalance ] = useState(null)
	const [ apiInfo, setApiInfo ] = useState({
		claimInfo: null,
		mintStatus: null
	})
	
	const checkWalletIsConnected = () => {
		return new Promise((resolve,reject) => {
			if ( ! ethereum ) {
				reject('not found ethereum')
			}
			ethereum.request({ method: 'eth_accounts' }).then(accounts=>{
				const account = accounts[0]
				if ( accounts.length !== 0 ) {
					dispatch({ type: 'SET_ACCOUNT', data: account })
				} else {
					console.log('No authorized account found')
				}
				resolve(account)
			})
		})
		
	}
	
	function getProof ( acc ) {
		getAccess(utils.getAddress(acc)).then(res => {
			if ( res.code === 200 ) {
				setApiInfo(res.data)
				// getTokens(acc)
			}
		})
	}
	
	function verifyAccess () {
		let { status } = apiInfo.mintStatus.status
		if ( status === 4 || status === 5 ) {
			return true
		} else {
			return apiInfo.claimInfo !== null
		}
	}
	
	function getBalance () {
		return new Promise(( resolve, reject ) => {
			_provider.getBalance(currAccessAccount).then(balance => {
				let etherString = ethers.utils.formatEther(balance)
				setBalance(parseFloat(etherString))
				resolve(parseFloat(etherString))
			})
		})
	}
	
	function getTokens ( account ) {
		getTokenIds(account).then(res => {
			if ( res.status === '1' ) {
				let arr = []
				res.result.forEach(item => arr.push(item.tokenID))
				
				const params = {
					owner: account.toLowerCase(),
					asset_contract_address: contractAddress.toLowerCase()
				}
				
				// getAssetsByAccount(params).then(res => {
				// 	console.log(res.assets)
				// })
			}
		})
	}
	
	function openMetaMaskUrl ( url ) {
		const a = document.createElement('a')
		a.href = url
		a.target = '_self'
		document.body.appendChild(a)
		a.click()
		a.remove()
	}
	
	const connectWalletHandler = async () => {
		if ( ! ethereum ) {
			if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
				openMetaMaskUrl('https://metamask.app.link/dapp/datingverse.org')
			} else {
				message.error('Error', 'Make sure you have Metamask installed!')
			}
		}else {
			ethereum.request({ method: 'eth_requestAccounts' })
				.then(res => {
						dispatch({ type: 'SET_ACCOUNT', data: res[0] })
					}
				)
				.catch(err => {
					console.log(err)
				})
		}
	}
	
	const mintNftHandler = async () => {
		try {
			if ( ethereum ) {
				if ( currChainId !== '0x1' ) {
					message.info('Tips', 'Please switch to main network!', false)
					return
				}
				let _balance
				await getBalance().then(res => {
					_balance = res
				}).catch(err=>{
					console.log(err)
				})
				if ( _props.count * price <= _balance ) {
					if ( ! apiInfo.mintStatus ) return
					let { status } = apiInfo.mintStatus
					if ( status === 0 ) {
						message.info('Tips', 'Mint not begin yet', false)
						return
					}
					const signer = _provider.getSigner()
					const nftContract = new ethers.Contract(contractAddress, abi, signer)
					if ( verifyAccess() ) {
						let { Index, Proof } = apiInfo.claimInfo
						message.info('Minting...', `It will take some time, please do not refresh the page.`, false)
						
						let nftTxn
						switch( status ) {
							case 1:
								nftTxn = await nftContract.diamondPreMint(Index, Proof, _props.count, { value: ethers.utils.parseEther(`${ _props.count * price }`) })
								break
							case 2:
								nftTxn = await nftContract.diamondMint(Index, Proof, _props.count, { value: ethers.utils.parseEther(`${ _props.count * price }`) })
								break
							case 3:
								nftTxn = await nftContract.whiteMint(Index, Proof, _props.count, { value: ethers.utils.parseEther(`${ _props.count * price }`) })
								break
							case 4:
								nftTxn = await nftContract.publicMint(_props.count, { value: ethers.utils.parseEther(`${ _props.count * price }`) })
								break
							case 5:
								// TODO tokenId
								nftTxn = await nftContract.refund(1)
								break
							default:
						}
						await nftTxn.wait()
						message.success('Success', `  `)
						hideToast()
					} else {
						message.error('Sorry', 'No Permission For Mint!')
					}
				} else {
					message.error('Error', 'Eth does not enough for minting, please check again!')
				}
			} else {
				console.log('Ethereum does not exist')
			}
		} catch( err ) {
			console.log(err)
			message.error('Error', err.error.message)
			hideToast()
		}
	}
	
	const connectWalletButton = () => {
		return ( <div onClick={ connectWalletHandler } className="mint-top-btn">
			MINT
		</div> )
	}
	
	const mintNftButton = () => {
		return ( <div onClick={ mintNftHandler } className="mint-top-btn"
									style={ { filter: apiInfo.mintStatus ? '' : 'grayscale(1)' } }>
			MINT NOW
		</div> )
	}
	
	function hideToast () {
		document.querySelector('.toast').style.display = 'none'
	}
	
	useEffect(() => {
		checkWalletIsConnected().then(res => {
			if ( ethereum && res ) {
				getProof(res)
			}
		}).catch(err=>{
			console.log(err)
		})
		if ( ethereum ) {
			ethereum.on('accountsChanged', ( accounts ) => {
				if ( accounts.length > 0 ) {
					dispatch({ type: 'SET_ACCOUNT', data: accounts[0] })
					getProof(accounts[0])
				}
			})
			
			ethereum.on('connect', (res) => {
				dispatch({type:'SET_CHAINID',data: res.chainId})
			});
			
			ethereum.on('chainChanged', (chainId) => {
				dispatch({type:'SET_CHAINID',data: chainId})
			});
		}
	}, [])
	
	return ( <div className="text-center mt20">
		{ currAccessAccount ? mintNftButton() : connectWalletButton() }
	</div> )
}

