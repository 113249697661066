import {Soon}     from './soon'

export const AboutDvc = (props)=>{
	return(
		<div className="main-wrap dvc">
			<div className="container">
				<h4 className="page-title mt60 mb60">ABOUT $DVC</h4>
				<div className="page-summary">
					<p>$DVC（Datingverse Coin）is designed as the currency and governance token of the Datingverse. The $DVC holder will share the profits of Datingverse and govern the DAO.</p>
				</div>
				<h4 className="page-title mid-title mt60 mb60">UTILITY OF $DVC</h4>
				<div className="grid grid-col-12">
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/1.png')} alt="" className="fea-img" />
						<p className="fea-des">Buy/Sell/Rent <br/>Dating Land for revenue</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/2.png')} alt="" className="fea-img" />
						<p className="fea-des">Subscribe to the<br/>creator’s content</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/3.png')} alt="" className="fea-img" />
						<p className="fea-des">Interactive datingwith<br/>strangers/creators</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/4.png')} alt="" className="fea-img" />
						<p className="fea-des">Buy/Sell creator’s<br/>content NFT</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/5.png')} alt="" className="fea-img" />
						<p className="fea-des">Subscribe creators</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/6.png')} alt="" className="fea-img" />
						<p className="fea-des">Gift buying and <br/>giving</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/7.png')} alt="" className="fea-img" />
						<p className="fea-des">Buy dating scene <br/>templates</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/dvc/8.png')} alt="" className="fea-img" />
						<p className="fea-des">Holding for earn</p>
					</div>
				</div>
				<h4 className="page-title mid-title mt30 mb30">TOKEN ECONOMICS</h4>
				<p className="page-title-small mb30">The plan for the allocation of the treasury</p>
				<img src={require('../img/dvc/content1.png')} alt="" className="dvc-img mb60" />
				<img src={require('../img/dvc/content1-m.png')} alt="" className="dvc-img-sm mb60" />
				<p className="page-title-small mb30">SOURCE OF TREASURY REVENUE</p>
				<img src={require('../img/dvc/content2.png')} alt="" className="dvc-img" />
				<img src={require('../img/dvc/content2-m.jpg')} alt="" className="dvc-img-sm mb60" />
				
				<h5 className="soon-title">More details coming soon</h5>
				<Soon/>
			</div>
		</div>
	)
}
