import { Soon }   from './soon'

export const Datingland = (props)=>{
	return(
		<div className="main-wrap land">
			<div className="container">
				<h4 className="page-title mt60 mb60">DATING LAND</h4>
				<div className="page-summary mb60">
					Dating Land is a permanent private space that users can "own" in the Datingverse, built on the Polygon mainnet based on the ERC1155 protocol, and can be freely given away or traded on NFT marketplaces such as OpenSea.
				</div>
				<div className="grid grid-col-12 gap-30 mb30">
					<div className="col-span-3 text-center">
						<img src={require('../img/land/1.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">Ownership</p>
						<p className="fea-des">Use the space by yourself or rent it to other creators for revenue</p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/2.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">Extra large 3D space</p>
						<p className="fea-des">With an area of about 10,000 square meters, it is possible to build a 3D space of buildings up to 100 meters high. The building function will be gradually opened  </p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/3.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">Creat Dating space</p>
						<p className="fea-des"> Create and save up to 5 Dating Space scene templates  </p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/4.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">Own subscription <br/>channel</p>
						<p className="fea-des"> Own content subscription channel and charge monthly subscription fees to fans  </p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/5.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">Have your <br/>own NFT sales area</p>
						<p className="fea-des"> Have your own NFT sales area. You can generate NFTs of your own content to sell to fans. You can also charge preset royalties when fans resell these NFTs  </p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/6.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20"> Right of seting fees</p>
						<p className="fea-des"> Can set fees to interact with fans in the Dating Space  </p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/7.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">ensure scarcity and <br/>value-add</p>
						<p className="fea-des"> We have not yet decided on the total number of Dating Lands, but we will certainly work to ensure scarcity and value-add. </p>
					</div>
					<div className="col-span-3 text-center">
						<img src={require('../img/land/8.png')} alt="" className="fea-img size90" />
						<p className="fea-title mt20">Revenue bonuses</p>
						<p className="fea-des"> Additional revenue bonuses when pledging $DVC  </p>
					</div>
				</div>
				<Soon/>
			</div>
		</div>
	)
}
