import {useState} from 'react'
import ReactDOM   from 'react-dom'
/**
 * @param {Boolean} props.show
 * @param {String} props.type
 * @param {String} props.title
 * @param {String} props.content
 * @param {Function} props.onClose
 * */
function MessageBox (props) {
	
	const {title, content, duration,hideIcon, type } = {...props};
	const [open, setOpen] = useState(true);
	const handleClose = (event, reason) => {
		setOpen(false);
		document.querySelector('#msg').remove()
	};
	// setTimeout(()=>{
	// 	document.querySelector('#msg').remove()
	// },duration || 5000)
	return (
		<div className="msg">
			<div className="msg-mask" onClick={handleClose}/>
			<div className="msg-content">
				<span className="msg-close" onClick={handleClose}/>
				<div className={'msg-icon ' + type} style={{display:hideIcon===false?'none':''}}/>
				<div className="msg-title" style={{marginTop:hideIcon===false?'0':''}}>{title?title:'Tips'}</div>
				<div className="msg-summary">{content||'loading'}</div>
			</div>
		</div>
	)
}

export const message = {
	dom: document.createElement('div'),
	info(title,content,icon){
		this.dom.id = 'msg'
		this.dom.className = 'msg_error'
		const JSXdom = (<MessageBox hideIcon={icon} title={title} content={content} type="error"/>)
		ReactDOM.render(JSXdom,this.dom)
		document.body.appendChild(this.dom);
	},
	success(title,content,duration){
		this.dom.id = 'msg'
		this.dom.className = 'msg_success'
		const JSXdom = (<MessageBox title={title} content={content} type="success"/>)
		ReactDOM.render(JSXdom,this.dom)
		document.body.appendChild(this.dom);
	},
	error(title,content,duration){
		this.dom.id = 'msg'
		this.dom.className = 'msg_error'
		const JSXdom = (<MessageBox title={title} content={content} type="error"/>)
		ReactDOM.render(JSXdom,this.dom)
		document.body.appendChild(this.dom);
	},
	hide () {
		document.querySelector('#msg').remove()
	}
	
}
