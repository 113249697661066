import axios from 'axios'

const req = axios.create({
	timeout: 　10000
})

// interceptors axios
req.interceptors.request.use(config => {
	return config
}, err => {
	Promise.reject(err)
})

req.interceptors.response.use(res => {
	if ( res.status === 200 ) {
		return Promise.resolve(res.data)
	}
}, err => {
	Promise.reject(err)
})

export default req

