export const Soon = (props) => {
	const isSafari = (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))
	return (
		<div className="soon">
			{/* <h5 className="soon-title">More details coming soon</h5> */}
			<div className="flex flex-btw">
				<div className="soon-left">
					<div className="platform-wrap">
						<img src={require('../img/soon/android.png')} style={{width:'20px'}} alt="" />
						<img src={require('../img/soon/ios.png')} style={{width:'18px'}} alt="" />
						<img src={require('../img/soon/windows.png')} style={{width:'20px'}} alt="" />
						<img src={require('../img/soon/vr.png')} style={{width:'23px'}} alt="" />
					</div>
				</div>
				<div className="soon-right">
					<div className="top flex flex-btw">
						<img className="top-icon" src={require('../img/about/diamond.png')} alt="" />
						<span className="top-date" style={{fontSize: isSafari?'38px':'40px'}}>Diamond Mint Date</span>
					</div>
					<div className="bot flex flex-center_hor">
						<span className="bot-icon"/>
						<span className="bot-date">05 / 19 / 22</span>
					</div>
				</div>
			</div>
		</div>
	)
}
