import SmoothScroll from 'smooth-scroll'
import { About }    from './components/about'

export const scroll = new SmoothScroll('a[href*="/#"]', {
  speed: 1000,
  speedAsDuration: true
})

function App() {
  
  return (
    <main className="main">
      <About/>
    </main>
  );
}

export default App;
